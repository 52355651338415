<template>
    <div class="content-wrapper">
        <bo-page-title />
        <div class="content pt-0">
            <div class="form-row mb-2">
                <div class="col-md-6">
                <div class="form-row">
                    <div class="col-md-12 mb-2">
                    <div class="box_call">
                        <div class="row align-items-center">
                        <div class="col-md-auto">
                            <div class="wrap_text">
                            <p>Tujuan</p>
                            <h3>{{unit.aru_unit_tujuan||"-"}}</h3>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-12" v-if="Object.keys(unit||{}).length && latestAntrean">
                        <div class="box_call bg_call1" @click="openAntrianAdmisi = true" style="cursor:pointer;" data-popup="tooltip"
                            title="" data-toggle="modal">
                            <div class="row align-items-center">
                            <div class="col-md-auto">
                                <div class="ic_left">
                                <i class="icon-megaphone"></i>
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <div class="wrap_text">
                                <p class="text_light">Panggil Antrian</p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="col-md-6">
                <div class="form-row" id="antrian-today">
                    <div class="col-md-12">
                    <div class="card mb-2">
                        <div class="card-header">
                        <h5 class="card-title font-weight-semibold">Detail</h5>
                        </div>
                        <div class="table-responsive mb-0">
                        <table class="table  table-bordered table-striped">
                            <tbody>
                            <tr>
                                <td>Total Antrian</td>
                                <td class="table-info"><strong>{{resume.totalAntrean||0}}</strong></td>
                            </tr>
                            <tr>
                                <td>Sudah dipanggil </td>
                                <td class="table-success"><strong>{{resume.totalDone||0}}</strong></td>
                            </tr>
                            <tr>
                                <td>VOID</td>
                                <td class="table-danger"><strong>{{resume.totalVoid||0}}</strong></td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        
		<validation-observer ref="VFormAntreanAdmisi">
            <b-modal v-model="openAntrianAdmisi" :title="'Antrian Admisi'" size="sm" hide-footer>    
                <div class="text-center" v-if="openAntrianAdmisi">
                    <div class="modal-shout-icon mb-3 d-inline-block alpha-info text-info rounded-circle">
                        <i class="icon-megaphone"></i>
                    </div>
                    <h6 class="mb-0">Memanggil Antrian</h6>
                    <h3 class="font-weight-semibold mb-0" style="font-size:36px;"> No.
                    {{latestAntrean.aadm_no_antrian||"-"}}</h3>
                    <small class="text-info mb-3">Pasien akan berubah status void setelah dipanggil 3x</small>
                    
                    <div v-if="latestAntrean.aadm_called > 3" class="form-group text-left mt-3" id="patientNotPresenceInfo">
                        <label for="patientNotPresent">Catatan Ketidakhadiran Pasien<strong class="text-danger">*</strong></label>
                        <textarea v-model="latestAntrean.aadm_notes" name="patientNotPresent" id="patientNotPresent" rows="3" class="form-control"
                        placeholder="cth. pasien tidak hadir setelah dipanggil 3x"></textarea>
                        
                        <VValidate :name="'Catatan'" v-model="latestAntrean.aadm_notes" :rules="{required: 1, min: 2}" />

                    </div>

                    <div v-if="latestAntrean.aadm_called > 3" class="mt-1">                
                        <a href="javascript:;" @click="voidPasien(latestAntrean)" class="btn btn-danger btn-labeled btn-labeled-left mr-1">
                        <b><i class="icon-checkmark"></i></b>VOID
                        </a>
                    </div>
                    <div v-else class="mt-1">
                        <a href="javascript:;" @click="hadirPasien(latestAntrean)" class="btn btn-success btn-labeled btn-labeled-left mr-1">
                        <b><i class="icon-checkmark"></i></b>Hadir
                        </a>
                        <a href="javascript:;" @click="panggilPasien(latestAntrean)" class="btn btn-warning btn-labeled btn-labeled-left">
                        <b><i class="icon-bell2"></i></b> Panggil
                        </a>
                    </div>
                </div>
            </b-modal>
        </validation-observer>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
const moment = require('moment')
import $ from 'jquery'
import Gen from '@/libs/Gen.js'

export default {
    extends: GlobalVue,  
    data() {
        return {
            unit : {},
            latestAntrean : {},
            resume : {
                totalAntrean : 0,
                totalVoid : 0,
                totalDone : 0
            },
            openAntrianAdmisi: false
        }
    },
    methods: {
        panggilPasien(antrean){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'panggil-pasien', id: antrean.aadm_id, aadm_called :  (this.latestAntrean.aadm_called + 1)}}, 
                "POST"
            ).then(()=>{
                antrean.to = 'Admisi'
                this.$socket.emit('panggil_pasien', antrean)
                this.latestAntrean.aadm_called = this.latestAntrean.aadm_called + 1         
            })
        },
        voidPasien(antrean){
            this.$refs['VFormAntreanAdmisi'].validate().then(success=>{
                if(success){
                    Gen.apiRest(
                        "/do/"+this.modulePage,
                        {data:{type:'update-status', id: antrean.aadm_id, aadm_status : 'VOID'}}, 
                        "POST"
                    ).then(()=>{
                        this.openAntrianAdmisi = false
                        this.apiGet()
                        this.$socket.emit('refresh_data', {to : 'Admisi'})
                        this.$socket.emit('refresh_data_all',{})
                        return this.$swal({
                            icon: 'success',
                            title: 'Berhasil mengubah status antrean'
                        })
                    })
                }
            })
        },
        hadirPasien(antrean){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'update-status', id: antrean.aadm_id, aadm_status : 'DONE'}}, 
                "POST"
            ).then(()=>{
                this.openAntrianAdmisi = false
                this.apiGet()
                this.$socket.emit('refresh_data', {to : 'Admisi'})
                this.$socket.emit('refresh_data_all',{})
                return this.$swal({
                    icon: 'success',
                    title: 'Berhasil mengubah status antrean'
                })
            })
        }
    },
    mounted(){
        this.sockets.subscribe('disp_refresh_data_all', function(data) {
            console.log(data)
            this.apiGet()
        })
        this.apiGet()
    },
    watch:{
        $route(){
            this.apiGet()
        },
        '$route.params.pageSlug':function(){
            this.$set(this,'filter',{})
        }
    },
    sockets: {
        connect() {
            this.isConnected = true
        },

        disconnect() {
            this.isConnected = false
        },

        messageChannel(data) {
            this.socketMessage = data
        },
    },
}
</script>